import React, { useEffect } from 'react';

const ECOM_URL = process.env.GATSBY_ECOM_URL ?? 'https://thepetlabco.com/';

const HomePage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = ECOM_URL;
    }, 1);
  }, []);

  return (
    <div>
      Redirecting to <a href={ECOM_URL}>{ECOM_URL}</a>...
    </div>
  );
};

export default HomePage;
